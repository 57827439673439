<template>
  <section
    class="section-container"
  >
    <v-row
      class="signin"
    >
      <v-col
        cols="8"
        class="float-left"
      >
        <h1><img src="Logo_GROUPE_ADINFO_Vertical_583x600.png"></h1>
      </v-col>
      <v-col
        cols="4"
        class="float-right"
      >
        <Heading :title="$t('forgotPassword.TITLE')" />
        <Description :description="$t('forgotPassword.DESCRIPTION')" />
        <v-col
          cols="12"
        >
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)">
              <v-row>
                <v-col>
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required|email"
                  >
                    <v-text-field
                      id="email"
                      v-model="email"
                      name="email"
                      type="email"
                      :label="$t('forgotPassword.EMAIL')"
                      :error="errors.length > 0"
                      :error-messages="errors[0]"
                      autocomplete="off"
                      required
                      outlined
                      dense
                      dark
                      filled
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center mt-5">
                  <v-btn
                    class="back-btn mr-3 float-left"
                    rounded
                    color="white"
                    @click="goBack()"
                  >
                    <v-icon class="mr-2">
                      mdi-arrow-left
                    </v-icon>
                    {{ $t('common.GO_BACK') }}
                  </v-btn>
                  <v-btn
                    class="submit-btn mr-3 float-right"
                    type="submit"
                    rounded
                    color="white"
                    dark
                  >
                    {{ $t('forgotPassword.RECOVER') }}
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-col>
      </v-col>
    </v-row>
    <ErrorMessage />
    <SuccessMessage />
  </section>
</template>

<script>
  import router from '@/router'
  import { mapActions } from 'vuex'
  import '@/sass/login.scss'

  export default {
    metaInfo () {
      return {
        title: this.$store.getters.appTitle,
        titleTemplate: `${this.$t('forgotPassword.TITLE')} - %s`,
      }
    },
    data () {
      return {
        email: '',
      }
    },
    methods: {
      ...mapActions(['forgotPassword']),
      async submit () {
        await this.forgotPassword({
          email: this.email,
        })
      },
      goBack () {
        router.go(-1)
      },
    },
    created () {
      if (this.$store.state.auth.isTokenSet) {
        router.push({ name: 'home' })
      }
    },
  }
</script>
